import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";

class OneTask extends React.PureComponent {
  state = {
    values: [],
  };

  componentDidMount() {
    Axios.get("https://max.nutritionchangechallengebackend.com/api/GroupTasks").then(
      (response) => {
        this.setState({
          values: response.data,
        });
      }
    );
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          {this.state.values.map((value: any) => (
            <div>
              <br />
              <div className="tema row" key={value.id}>
                <p className="text-forum">{value.task} </p>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(OneTask);
