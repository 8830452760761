import * as React from "react";
import { connect } from "react-redux";
import profilImage from "../../img/profil.png";
import Axios from "axios";
import { Link } from "react-router-dom";

interface IReceptiAdmin {
  values: any;
  title: any;
  shortDescription: any;
  ingredients: any;
  description: any;
  category: any;
  state: any;
  imagePath: any;
  timeToPrepare: any;
}

class ReceptiAdmin extends React.PureComponent<{}, IReceptiAdmin> {
  state = {
    values: [],
    title: "",
    shortDescription: "",
    ingredients: "",
    description: "",
    category: "",
    state: "",
    imagePath: "",
    timeToPrepare: "",
  };

  componentDidMount() {
    Axios.get("https://max.nutritionchangechallengebackend.com/api/recipes/list/").then(
      (response) => {
        this.setState({
          values: response.data,
        });
      }
    );
  }

  deleteRecept(id: any): void {
    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/recipes/" + id;
    //alert(url);
    xhr.open("DELETE", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        window.location.replace("/adminpanel");
      } else {
        //alert("ne radi");
      }
    };
    xhr.send(null);

    this.setState({});
  }

  addRecept(e: any): void {
    e.preventDefault();

    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/recipes/";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenUser")
    );

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);

        window.location.replace("/adminpanel");
      }
    };

    var data = JSON.stringify({
      title: this.state.title,
      shortDescription: this.state.shortDescription,
      ingredients: this.state.ingredients,
      description: this.state.description,
      category: this.state.category,
      //state: "",
      //imagePath: "",
      timeToPrepare: this.state.timeToPrepare,
    });

    xhr.send(data);

    this.setState({});
  }

  public render() {
    return (
      <React.Fragment>
        <div className="prijava-profil">
          <div className="jedanrecept">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <br />
                  <br />
                  <h1 className="text ">Svi recepti</h1>
                  <br />
                  <br />
                  <form onSubmit={(e) => this.addRecept(e)}>
                    <p className="text">Naslov</p>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      required
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                    <br />
                    <br />
                    <p className="text">Kratak opis</p>
                    <input
                      type="text"
                      id="shortDescription"
                      name="shortDescription"
                      onChange={(e) =>
                        this.setState({ shortDescription: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <p className="text">Sastojci</p>
                    <input
                      type="text"
                      id="ingredients"
                      name="ingredients"
                      onChange={(e) =>
                        this.setState({ ingredients: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <p className="text">Način pripreme</p>
                    <input
                      type="text"
                      id="description"
                      name="description"
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <p className="text">Kategorija</p>
                    <input
                      type="text"
                      id="category"
                      name="category"
                      onChange={(e) =>
                        this.setState({ category: e.target.value })
                      }
                    />
                    <br />
                    <br />

                    <p className="text">Vreme pripreme</p>
                    <input
                      type="text"
                      id="timeToPrepare"
                      name="timeToPrepare"
                      onChange={(e) =>
                        this.setState({ timeToPrepare: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <br />
                    <section className="intro">
                      <button
                        id="js-trigger-overlay"
                        className="intro"
                        type="submit"
                      >
                        Objavi
                      </button>
                    </section>
                    <br />
                    <br />
                    <br />
                  </form>
                  <br />
                  <br />
                  {this.state.values.map((value: any) => (
                    <div key={value.id}>
                      <br />
                      <br />
                      <div className="tema">
                        <h1
                          className="text rightX"
                          onClick={() => this.deleteRecept(value.id)}
                        >
                          x
                        </h1>
                        <br />
                        <h1 className="text">{value.title}</h1>
                        <br />
                        <p className="text-forum-center">
                          {value.timeToPrepare}
                        </p>
                        <br />
                        <p className="text-forum-center">{value.category}</p>
                        <br />
                        <p className="text-forum-center">{value.ingredients}</p>
                        <br />
                        <p className="text-forum-center">
                          {value.shortDescription}
                        </p>
                        <br />
                        <p className="text-forum-center">{value.description}</p>
                        <br />
                        <br />
                      </div>
                    </div>
                  ))}
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(ReceptiAdmin);
