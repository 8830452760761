import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import Axios from "axios";
import "i18next";
import i18next from "i18next";

interface IComment {
  id: any;
  text: string;
}

interface ComponentProps extends RouteComponentProps<IComment> {}

class TopicDetails extends React.PureComponent<ComponentProps> {
  newid = this.props.match.params.id;

  state = {
    values: [],
    text: "",
  };

  componentDidMount() {
    console.log(this.newid);
    Axios.get(
      "https://max.nutritionchangechallengebackend.com/api/ForumMessages/list/" +
        this.newid
    ).then((response) => {
      this.setState({
        values: response.data,
      });
    });
  }

  commentSubmit(e: any): void {
    e.preventDefault();
    //console.log(this.state);

    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/ForumMessages/";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenUser")
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        window.location.reload();
      }
    };

    this.setState({});

    var data = JSON.stringify({
      forumThemeId: this.newid,
      text: this.state.text,
    });
    //console.log(data);
    xhr.send(data);
  }

  public render() {
    return (
      <React.Fragment>
        <div className="forumdetaljinje">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <br />
                <br />
                <h1 className="text">{i18next.t("Saznaj vise")}</h1>
                <br />
                <br />
                <br />

                {this.state.values.map((value: any) => (
                  <div>
                    <div className="tema" key={value.id}>
                      <div className="text-forum">
                        <p>{value.text}</p>
                        <p className="text-forum-saznaj">{value.authorName}</p>
                      </div>
                    </div>
                    <br />
                  </div>
                ))}
                <br />
                <br />
                <br />
                <br />

                <form onSubmit={(e) => this.commentSubmit(e)}>
                  <br />
                  <textarea
                    id="textarea"
                    name="comm"
                    placeholder={i18next.t("Komentar upisi ovde")}
                    onChange={(e) => this.setState({ text: e.target.value })}
                  ></textarea>
                  <br />
                  <br />
                  <br />
                  <br />
                  <section className="intro">
                    <button
                      id="js-trigger-overlay"
                      className="intro"
                      type="submit"
                    >
                      {i18next.t("Pošalji")}
                    </button>
                  </section>
                </form>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(TopicDetails);
