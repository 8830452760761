import * as React from "react";
import { connect } from "react-redux";
import profilImage from "../../img/profil.png";
import Axios from "axios";
import { Link } from "react-router-dom";
import "../i18n";
import i18next from "i18next";

class OneLunch extends React.PureComponent {
  state = {
    values: [],
  };

  componentDidMount() {
    Axios.get(
      "https://max.nutritionchangechallengebackend.com/api/recipes/list/Rucak"
    ).then((response) => {
      this.setState({
        values: response.data,
      });
    });
  }
  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="jedanrecept">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  {this.state.values.map((value: any) => (
                    <div>
                      <br />
                      <br />
                      <div className="tema" key={value.id}>
                        <br />
                        <h1 className="text">{value.title}</h1>
                        <img className="center-image" src={profilImage} />
                        <br />
                        <p className="text-forum-center">{value.ingredients}</p>
                        <br />
                        <p className="text-forum-center">
                          {value.shortDescription}
                        </p>
                        <Link
                          className="saznaj"
                          to={`/recipedetails/${value.id}/${value.title}/${value.ingredients}/${value.description}/${value.category}/${value.timeToPrepare}`}
                          style={{ textDecoration: "none" }}
                        >
                            {i18next.t("Saznaj vise")}
                        </Link>
                        <br />
                      </div>
                    </div>
                  ))}
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(OneLunch);
