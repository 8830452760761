import * as React from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { Link } from "react-router-dom";

class AnketeAdmin extends React.PureComponent {
  state = {
    values: [],
  };

  componentDidMount() {
    Axios.get("https://max.nutritionchangechallengebackend.com/api/surveys/list").then(
      (response) => {
        this.setState({
          values: response.data,
        });
      }
    );
  }

  deleteAnketa(id: any): void {
    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/surveys/" + id;
    //alert(url);
    xhr.open("DELETE", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        window.location.replace("/adminpanel");
      } else {
        //alert("ne radi");
      }
    };
    xhr.send(null);

    this.setState({});
  }

  public render() {
    return (
      <React.Fragment>
        <div className="prijava-profil">
          <br />
          <br />
          <h1 className="text">Admin panel</h1>
          <br />
          {this.state.values.map((value: any) => (
            <div className="container-fluid" key={value.id}>
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <br />
                  <div className="tema">
                    <h1
                      className="xright"
                      onClick={() => this.deleteAnketa(value.id)}
                    >
                      x
                    </h1>
                    <br />
                    <h2 className="text-forum">{value.fullname}</h2>
                    <p className="text-forum">{value.birth}</p>
                    <p className="text-forum">{value.number}</p>
                    <p className="text-forum-saznaj">
                      <Link
                        to={`/anketadetaljnije/${value.id}/${value.fullname}/${value.city}/${value.address}/${value.birth}/${value.number}/${value.motive}/${value.obligations}/${value.sweets}/${value.diet}/${value.difficulty}/${value.medicines}/${value.opinion}/${value.mistakes}/${value.weight}/${value.height}/${value.magicNumber}/${value.training}/${value.measurement}/${value.questions}/${value.state}/`}
                        style={{ textDecoration: "none" }}
                        key={value.id}
                      >
                        saznaj vise &gt;
                      </Link>
                    </p>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(AnketeAdmin);
