import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import "../i18n";
import i18next from "i18next";

class OneGroupChat extends React.PureComponent {
  state = {
    values: [],
    text: "",
    reciverNickName: "",
  };

  componentDidMount() {
    Axios({
      url: "https://max.nutritionchangechallengebackend.com/api/messages",
      method: "GET",
      headers: {
        authorization: "Bearer " + localStorage.getItem("tokenUser"),
      },
    }).then((response) => {
      this.setState({
        values: response.data,
      });
    });
  }

  commentSubmit(e: any): void {
    e.preventDefault();
    //console.log(this.state);

    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/messages";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenUser")
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        window.location.reload();
      }
    };

    this.setState({});

    var data = JSON.stringify({
      text: this.state.text,
      reciverNickName: "all",
    });
    //console.log(data);
    xhr.send(data);
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          {this.state.values.map((value: any) => (
            <div>
              <div className="tema">
                <h3 className="text-forum">{value.text}</h3>
                <h5 className="text-forum">{value.sender.nickName}</h5>
                <p className="text-forum">{value.timeStamp.slice(0, 10)}</p>
              </div>
              <br />
            </div>
          ))}
          <br />
          <br />
          <form onSubmit={(e) => this.commentSubmit(e)}>
            <br />
            <textarea
              id="textarea"
              name="comm"
              placeholder= {i18next.t("tekst poruke")}
              onChange={(e) => this.setState({ text: e.target.value })}
            ></textarea>
            <br />
            <br />

            <section className="intro">
              <button id="js-trigger-overlay" className="intro" type="submit">
                {i18next.t("posalji poruku")}
              </button>
            </section>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(OneGroupChat);
