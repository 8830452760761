import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import Axios from "axios";
import "./i18n";
import i18next from "i18next";

interface IInProgress {
  startingDate: any;
}

interface ComponentProps extends RouteComponentProps<IInProgress> {}

class Home extends React.PureComponent<ComponentProps> {
  state = {
    startingDate: "",
    day: "",
  };

  componentDidMount() {
    let ceodatum, day, month, year, time;

    let countdown = document.getElementById(
      "countdown"
    ) as HTMLParagraphElement;

    let countdowntext = document.getElementById(
      "countdown-text"
    ) as HTMLParagraphElement;

    Axios.get<any>(
      "https://max.nutritionchangechallengebackend.com/api/Programs/inProgress"
    ).then((response) => {
      if (response.status == 200) {
        countdown.innerHTML = i18next.t("Izazov je u toku");
        countdowntext.style.display = "none";
      } else {
        Axios.get<any>(
          "https://max.nutritionchangechallengebackend.com/api/Programs/next"
        ).then((response) => {
          if (response.status == 200) {
            this.setState({
              startingDate: response.data["startingDate"],
            });
            countdowntext.style.display = "block";
            countdowntext.innerHTML = i18next.t("Izazov počinje za:");
            //ovde je brojaca
            ceodatum = this.state.startingDate;
            ceodatum = ceodatum.split(/[ .\/]+/);
            day = ceodatum[1];
            month = ceodatum[0];
            year = ceodatum[2];
            time = ceodatum[3];
            let noviDatum = month + " " + day + " " + year + " " + time;
            let countDownDate = new Date(noviDatum).getTime();

            let x = setInterval(function () {
              // Get today's date and time
              let now = new Date().getTime();

              // Find the distance between now and the count down date
              let distance = countDownDate - now;
              //console.log(countDownDate);
              //console.log(now);
              // Time calculations for days, hours, minutes and seconds
              let days = Math.floor(distance / (1000 * 60 * 60 * 24));
              let hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              let minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
              );
              let seconds = Math.floor((distance % (1000 * 60)) / 1000);

              // Output the result in an element with id="demo"
              if (countdown) {
                countdown.innerHTML =
                  days +
                  " : " +
                  hours +
                  " : " +
                  minutes +
                  "  : " +
                  seconds +
                  " ";
              }
              if (distance < 0) {
                if (countdown) {
                  countdown.innerHTML = i18next.t("Izazov je u toku");
                  countdowntext.style.display = "none";
                }
                if (countdowntext) countdowntext.style.display = "none";
              }
            }, 100);
          } else {
            countdown.innerHTML = i18next.t("Izazov u propremi");
          }
        });
      }
    });

    //console.log(this.state.startingDate);
    /*if (this.state.startingDate != undefined) {
        ceodatum = this.state.startingDate;
        console.log(ceodatum);
        ceodatum = ceodatum.split(/[ .\/]+/);
        day = ceodatum[1];
        month = ceodatum[0];
        year = ceodatum[2];
        time = ceodatum[3];
        console.log(day);
        console.log(month);
        console.log(year);
        console.log(time);
      } else {
        ceodatum = "01 Oct 2020 10:00:00";
        day = ceodatum.slice(0, 2);
        month = ceodatum.slice(3, 6);
        year = ceodatum.slice(7, 10);
        time = ceodatum.slice(10, 18);
      }

      let noviDatum = month + " " + day + " " + year + " " + time;
      //console.log(noviDatum);
      let countDownDate = new Date(noviDatum).getTime();
      //console.log(new Date("11 10 2022 10:00:00"));
      // Update the count down every 1 second
      let x = setInterval(function () {
        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;
        //console.log(countDownDate);
        //console.log(now);
        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        let countdown = document.getElementById(
          "countdown"
        ) as HTMLParagraphElement;

        let countdowntext = document.getElementById(
          "countdown-text"
        ) as HTMLParagraphElement;

        // Output the result in an element with id="demo"
        if (countdown) {
          countdown.innerHTML =
            days + " : " + hours + " : " + minutes + "  : " + seconds + " ";
        }

        // If the count down is over, write some text
        //console.log(distance);
        if (distance < 0) {
          //clearInterval(x);
          if (countdown) {
            countdown.innerHTML = "Izazov je u toku";
          }
          if (countdowntext) countdowntext.style.display = "none";
        }
      }, 100);
    });*/
    // Set the date we're counting down to
    //console.log(this.state.startingDate);
    //let countDownDate = new Date("Oct 15 2022 10:00:00").getTime();
  }

  onChangeLang = (option: any) => {
    localStorage.setItem("lang", option);
    window.location.reload();
  };

  public render() {
    const lang = localStorage.getItem("lang") || "ser";
    i18next.changeLanguage(lang);

    return (
      <React.Fragment>
        <div>
          <div className="first-screen">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">

                  <img
                    className="flagPocetna"
                    src={"img/serbia.png"}
                    width="25"
                    height="25"
                    onClick={() => this.onChangeLang("ser")}
                  />
                  <img
                    className="flagPocetna"
                    src={"img/eng.png"}
                    width="25"
                    height="25"
                    onClick={() => this.onChangeLang("eng")}
                  />
                  <img
                    className="flagPocetna"
                    src={"img/german.png"}
                    width="25"
                    height="25"
                    onClick={() => this.onChangeLang("ger")}
                  />
                  
                  
                  
                </div>
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <p id="countdown-text" className="intro"></p>
                  <p id="countdown" className="intro"></p>
                  <section className="intro">
                    <button
                      id="js-trigger-overlay"
                      className="intro"
                      type="button"
                    >
                      <NavLink tag={Link} to="/questionnaire">
                        {i18next.t("Prijavi se")}
                      </NavLink>
                    </button>
                  </section>
                  <br />
                  <section className="intro">
                    <button
                      id="js-trigger-overlay"
                      className="intro"
                      type="button"
                    >
                      <NavLink tag={Link} to="/login">
                        {i18next.t("Profile")}
                      </NavLink>
                    </button>
                  </section>
                  <p className="centerTextRights">
                    Sva prava zadrzana / Alle Rechte vorbehalten / All rights
                    reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="scroll-down"> </div>
          <div className="second-screen">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <br />
                  <br />
                  <h1 className="intro text">Max Challenge</h1>
                  <br />
                  <br />
                  <p className="intro text">
                     {i18next.t("Hvala Vam na interesovanju")}
                  </p>
                  <p className="intro text">
                    {i18next.t("Najcesci razlozi zbog")}
                  </p>
                  <p className="intro text">
                     {i18next.t("Challenge traje 4 nedelje")}
                  </p>
                  <p className="intro text">
                    {i18next.t("Nakon")}
                  </p>
                  <p className="intro text">
                    {i18next.t("Radujemo")}
                  </p>
                </div>
                <div className="col-md-3">
                  <p className="drustvene-mreze"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Home);
function inProgressStatus() {
  throw new Error("Function not implemented.");
}
