import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import Axios from "axios";
import TemaAdmin from "./TemaAdmin";

interface IForumAdmin {
  theme: any;
  category: any;
}

class ForumAdnim extends React.PureComponent<{}, IForumAdmin> {
  constructor(props: {}) {
    super(props);

    this.state = {
      theme: "",
      category: "",
    };
  }

  addThemeSubmit(e: any): void {
    e.preventDefault();

    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/ForumTheme";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenUser")
    );

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);

        window.location.replace("/adminpanel");
      }
    };
    this.setState({});

    var data = JSON.stringify({
      title: this.state.theme,
      category: "Zdrav zivot",
    });

    xhr.send(data);
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="prijava-profil">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <h1 className="text ">Sve teme na forumu</h1>
                  <br />
                  <br />
                  <br />
                  <form onSubmit={(e) => this.addThemeSubmit(e)}>
                    <p className="text">Naslov teme: </p>
                    <input
                      type="text"
                      id="theme"
                      name="theme"
                      required
                      onChange={(e) => this.setState({ theme: e.target.value })}
                    />
                    <br />
                    <br />
                    <br />

                    <section className="intro">
                      <button
                        id="js-trigger-overlay"
                        className="intro"
                        type="submit"
                      >
                        Dodaj temu
                      </button>
                    </section>
                  </form>
                  <br />
                  <br />
                  <br />
                  <TemaAdmin />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(ForumAdnim);
