import * as React from "react";
import { connect } from "react-redux";

interface IStateNovi {
  StartingDate: any;
  EndingDate: any;
}

class AdminNoviProgram extends React.PureComponent<{}, IStateNovi> {
  constructor(props: {}) {
    super(props);

    this.state = {
      StartingDate: "",
      EndingDate: "",
    };
  }

  addTerminSubmit(e: any): void {
    e.preventDefault();

    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/Programs";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);

        window.location.replace("/adminpanel");
      }
    };

    var data = JSON.stringify({
      StartingDate: this.state.StartingDate,
      EndingDate: this.state.EndingDate,
    });

    xhr.send(data);

    this.setState({});
  }

  public render() {
    return (
      <React.Fragment>
        <div>
          <div className="prijava-profil">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <h1 className="text ">Dodaj novi izazov</h1>
                  <h4 className="text pink">npr: 01 Nov 2021 10:00:00</h4>
                  <br />
                  <br />
                  <br />
                  <form onSubmit={(e) => this.addTerminSubmit(e)}>
                    <p className="text">Pocetak izazova</p>
                    <input
                      type="text"
                      id="StartingDate"
                      name="StartingDate"
                      required
                      onChange={(e) =>
                        this.setState({ StartingDate: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <br />
                    <p className="text">Kraj izazova</p>
                    <input
                      type="text"
                      id="EndingDate"
                      name="EndingDate"
                      required
                      onChange={(e) =>
                        this.setState({ EndingDate: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <p id="error"> </p>
                    <section className="intro">
                      <button
                        id="js-trigger-overlay"
                        className="intro"
                        type="submit"
                      >
                        Dodaj izazov
                      </button>
                    </section>
                  </form>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(AdminNoviProgram);
