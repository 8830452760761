import * as React from "react";
import { connect } from "react-redux";
import Axios from "axios";

class TerminiAdmin extends React.PureComponent {
  state = {
    values: [],
  };

  componentDidMount() {
    Axios.get(
      "https://max.nutritionchangechallengebackend.com/api/appointments/list/pending"
    ).then((response) => {
      this.setState({
        values: response.data,
      });
    });
  }

  deleteTermin(id: any): void {
    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/GroupTasks/" + id;
    //alert(url);
    xhr.open("DELETE", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        window.location.replace("/adminpanel");
      } else {
        //alert("ne radi");
      }
    };
    xhr.send(null);

    this.setState({});
  }

  public render() {
    return (
      <React.Fragment>
        <div className="container-fluid prijava-profil">
          <div className="row">
            <div className="col-md-4"></div>

            <div className="col-md-4">
              <br />
              <h1 className="text">Svi termini</h1>
              <br />
              {this.state.values.map((value: any) => (
                <div key={value.id}>
                  <br />
                  <div className="tema">
                    <h1
                      className="xright"
                      onClick={() => this.deleteTermin(value.id)}
                    >
                      x
                    </h1>
                    <p className="text-forum">
                      <br />
                      <span className="pinkspan">Vreme (g/m/d):&nbsp;</span>
                      {value.time}
                    </p>
                    <p className="text-forum">
                      <span className="pinkspan">Korisničko ime:&nbsp;</span>
                      {value.userName}
                    </p>
                    <p className="text-forum">
                      <span className="pinkspan">Ime i prezime:&nbsp;</span>
                      {value.fullName}
                    </p>
                    <p className="text-forum">
                      <span className="pinkspan">Email:&nbsp;</span>{" "}
                      {value.email}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(TerminiAdmin);
