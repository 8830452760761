import * as React from "react";
import { connect } from "react-redux";
import "./i18n";
import i18next from "i18next";

interface IStateLogin {
  email: string;
  password: string;
}

class Login extends React.PureComponent<{}, IStateLogin> {
  constructor(props: {}) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };
  }

  loginSubmit(e: any): void {
    e.preventDefault();

    var errorp = document.getElementById("error") as HTMLParagraphElement;

    var xhr = new XMLHttpRequest();
    var url = "https://max.nutritionchangechallengebackend.com/api/Users/login";
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);

        localStorage.setItem("tokenUser", json.token);
        localStorage.setItem("fullNameUser", json.fullName);
        localStorage.setItem("profileImgPathUser", json.profileImgPath);
        localStorage.setItem("instructorUser", json.isInstructor);

        if (!json.isInstructor) {
          window.location.replace("/profile/" + json.token);
          //window.location.href = "//profile/" + json.token;
        } else {
          window.location.replace("/adminpanel");
          //window.location.href = "//adminpanel";
        }
      }

      if (xhr.status === 401) {
        errorp.innerHTML = "Pogresili ste email ili sifru!";
      }
    };

    var data = JSON.stringify({
      email: this.state.email,
      password: this.state.password,
    });

    xhr.send(data);

    this.setState({});
  }

  onChangeLang = (option: any) => {
    localStorage.setItem("lang", option);
    //window.alert(option);
    window.location.reload();
  };

  public render() {
    const lang = localStorage.getItem("lang") || "ser";
    i18next.changeLanguage(lang);

    return (
      <React.Fragment>
        <div>
          <div className="prijava-profil">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  

                   <img
                    className="flagPocetna"
                    src={"img/serbia.png"}
                    width="25"
                    height="25"
                    onClick={() => this.onChangeLang("ser")}
                  />
                  <img
                    className="flagPocetna"
                    src={"img/eng.png"}
                    width="25"
                    height="25"
                    onClick={() => this.onChangeLang("eng")}
                  />
                  <img
                    className="flagPocetna"
                    src={"img/german.png"}
                    width="25"
                    height="25"
                    onClick={() => this.onChangeLang("ger")}
                  />
                 
                </div>
                <div className="col-md-4">
                  <br />
                  <br />
                  <h1 className="text "> {i18next.t("Logovanje")}</h1>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <form onSubmit={(e) => this.loginSubmit(e)}>
                    <p className="text">{i18next.t("Email")}</p>
                    <input
                      type="email"
                      id=""
                      name=""
                      required
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                    <br />
                    <br />
                    <br />
                    <p className="text">{i18next.t("Sifra")}</p>
                    <input
                      type="password"
                      id=""
                      name=""
                      required
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <br />
                    <br />
                    <br />
                    <p id="error"> </p>
                    <section className="intro">
                      <button
                        id="js-trigger-overlay"
                        className="intro"
                        type="submit"
                      >
                        {i18next.t("Prijavi se")}
                      </button>
                    </section>
                  </form>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Login);
