import * as React from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import "./i18n";
import i18next from "i18next";

export default class NavMenu extends React.PureComponent<
  {},
  { isOpen: boolean }
> {
  public state = {
    isOpen: false,
  };

  onChangeLang = (option: any) => {
    localStorage.setItem("lang", option);
    window.location.reload();
  };

  public render() {
    const lang = localStorage.getItem("lang") || "ser";
    i18next.changeLanguage(lang);

    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
          light
        >
          
          <img
            className="flag"
            src={"img/serbia.png"}
            width="25"
            height="25"
            onClick={() => this.onChangeLang("ser")}
          />
          <img
            className="flag"
            src={"img/eng.png"}
            width="25"
            height="25"
            onClick={() => this.onChangeLang("eng")}
          />
          <img
            className="flag"
            src={"img/german.png"}
            width="25"
            height="25"
            onClick={() => this.onChangeLang("ger")}
          />
          
          <Container>
            <NavbarBrand tag={Link} to="/"></NavbarBrand>
            <NavbarToggler onClick={this.toggle} className="mr-2" />

            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={this.state.isOpen}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="nav" to="/profile">
                    {i18next.t("Profile")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav" to="/rangList">
                    {i18next.t("Rang list")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav" to="/tasks">
                    {i18next.t("Tasks")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav" to="/appointment">
                    {i18next.t("Request an appointment")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav" to="/rules">
                    {i18next.t("Rules")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav" to="/forum">
                    {i18next.t("Forum")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav" to="/recepies">
                    {i18next.t("Recipes")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="nav" to="/groupchat">
                    {i18next.t("Group chat")}
                  </NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
}
