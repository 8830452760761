import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import Axios from "axios";
import profilImage from "../../img/profil.png";

interface IRecipe {
  id: any;
  title: any;
  shortDescription: any;
  ingredients: any;
  description: any;
  category: any;
  timeToPrepare: any;
}

interface RecipeProps extends RouteComponentProps<IRecipe> {}

class RecipeDetails extends React.PureComponent<RecipeProps> {
  newid = this.props.match.params.id;

  state = {
    values: [],
    id: "",
    title: "",
    shortDescription: "",
    ingredients: "",
    description: "",
    category: "",
    timeToPrepare: "",
  };

  componentDidMount() {
    console.log(this.newid);
    Axios.get<any>(
      "https://max.nutritionchangechallengebackend.com/api/recipes/" + this.newid
    ).then((response) => {
      this.setState({
        values: response.data,
        titlet: response.data["title"],
        shortDescription: response.data["shortDescription"],
        ingredients: response.data["ingredients"],
        description: response.data["description"],
        category: response.data["category"],
        timeToPrepare: response.data["timeToPrepare"],
      });
    });
  }

  public render() {
    return (
      <React.Fragment>
        <div className="forumdetaljinje">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <br />
                <br />
                <h1 className="text"> </h1>
                <br />
                <br />
                <br />
                <div>
                  <div className="tema">
                    <div className="text-forum">
                      <h1>{this.props.match.params.title}</h1>
                      <p>{this.props.match.params.timeToPrepare}</p>
                      <br />
                      <p>{this.props.match.params.ingredients}</p>
                      <p>{this.props.match.params.description}</p>
                    </div>
                  </div>
                  <br />
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(RecipeDetails);
